import { useEffect, useState } from 'react';
import {
    IconButton,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Stack,
    Typography,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteIcon from '@mui/icons-material/Delete';

import userService from '../../services/userService';
import { BackButton, StyledTableCell } from '../StyledComponents';
import PasswordDialog from './PasswordDialog';
import useErrorHandler from '../../services/error-handler';
import RemoveDialog from './RemoveDialog';

/**
 * отображение строки пользователя
 * user - объект данных пользователя
 * onChange(user) - функция вызывается при каждом изменении параметра (роль, активность)
 * onPasswordButtonClick(user) - функция обработки нажатия на клавишу сменить пароль
 * onRemoveButtonClick(user) - функция обработки нажатия на клавишу удалить
 */
const UserStr = ({ user, onChange, onPasswordButtonClick, onRemoveButtonClick }) => {
    if (!user) return;

    const onRoleChange = (event) => {
        const val = Number(event.target.value);
        const newUser = { ...user, role_id: val };
        onChange(newUser);
    };

    const onActiveChange = (event) => {
        const val = Boolean(event.target.checked);
        const newUser = { ...user, is_active: val };
        onChange(newUser);
    };

    return (
        <TableRow hover>
            <TableCell>{user.login}</TableCell>
            <TableCell>{user.first_name}</TableCell>
            <TableCell>{user.last_name}</TableCell>
            <TableCell>
                <Tooltip title="Роль пользователя в системе" placement="right-end">
                    <Select
                        size="small"
                        sx={{ minWidth: 175 }}
                        value={user.role_id}
                        onChange={onRoleChange}>
                        <MenuItem value={1}>Пользователь</MenuItem>
                        <MenuItem value={2}>Администратор</MenuItem>
                    </Select>
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip
                    title='Если пользователь "Отключен" то он не сможет
                                            войти в систему, даже зная логин и пароль'>
                    <Switch checked={user.is_active} onChange={onActiveChange} />
                </Tooltip>
            </TableCell>
            <TableCell align="right">
                <Tooltip title="Сброс пароля пользователя">
                    <IconButton color="error" onClick={() => onPasswordButtonClick(user)}>
                        <LockResetIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить пользователя">
                    <IconButton color="error" onClick={() => onRemoveButtonClick(user)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const UserManager = () => {
    const [users, setUsers] = useState([]); //массив пользователей
    const [user, setUser] = useState(null); //используется для отображения диалога смены пользователя
    const [removeUser, setRemoveUser] = useState(null); //используется для отображения диалога удаления пользователя

    const errorHandler = useErrorHandler();

    /**
     * получить список пользователей
     */
    const getUsersList = async () => {
        try {
            const list = await userService.usersList();
            setUsers(list.data);
        } catch (err) {
            errorHandler(err);
        }
    };

    /**
     * применить изменения в пользователе
     */
    const applyUser = async (user) => {
        try {
            await userService.saveChanges(user);
            setUser(null);
            getUsersList();
        } catch (err) {
            errorHandler(err);
        }
    };

    /**
     * изменение пароля пользователя
     */
    const applyPassword = async (password) => {
        try {
            await userService.changePassword(user.user_id, password);
            setUser(null);
        } catch (err) {
            errorHandler(err);
        }
    };
    const onRemoveUser = async () => {
        try {
            await userService.removeUser(removeUser.user_id);
            setRemoveUser(null);
            getUsersList();
        } catch (err) {
            errorHandler(err);
        }
    };

    useEffect(() => {
        getUsersList();
    }, []);

    const renderRows = () => {
        return users.map((user) => {
            return (
                <UserStr
                    key={user.user_id}
                    user={user}
                    onChange={applyUser}
                    onPasswordButtonClick={() => setUser(user)}
                    onRemoveButtonClick={() => setRemoveUser(user)}
                />
            );
        });
    };

    return (
        <>
            <Stack direction="row">
                <BackButton />
                <Typography variant="h5" sx={{ marginTop: '0.2em' }}>
                    Управление пользователями
                </Typography>
            </Stack>
            <TableContainer component={Paper}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Логин</StyledTableCell>
                            <StyledTableCell>Имя</StyledTableCell>
                            <StyledTableCell>Фамилия</StyledTableCell>
                            <StyledTableCell>Роль</StyledTableCell>
                            <StyledTableCell>Активен</StyledTableCell>
                            <StyledTableCell>Действия</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderRows()}</TableBody>
                </Table>
            </TableContainer>
            <PasswordDialog user={user} onCancel={() => setUser(null)} onApply={applyPassword} />
            <RemoveDialog
                user={removeUser}
                onClose={() => setRemoveUser(null)}
                onApply={onRemoveUser}
            />
        </>
    );
};

export default UserManager;
