import api from '../http';

export default class UserService {
    static async usersList() {
        return api.get('/user/users');
    }

    static async saveChanges(user) {
        return api.post('/user/apply', { user });
    }

    static async changePassword(user_id, newPassword) {
        return api.post('/user/password', { id: user_id, password: newPassword });
    }

    static async checkTg(userId) {
        return api.get(`/user/tg/${userId}`);
    }

    static async removeUser(userId) {
        return api.delete(`/user/${userId}`);
    }
}
