import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from '@mui/material';

import { StyledDialogTitle } from '../StyledComponents';

const RemoveDialog = ({ user, onApply, onClose }) => {
    if (!user) return;

    return (
        <Dialog open={user} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Удаление пользователя</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        Вы уверены, что хотите удалить пользователя <b>{user.login}</b>?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={onApply} variant="contained" autoFocus>
                    Удалить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveDialog;
