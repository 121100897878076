import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import TelegramIcon from '@mui/icons-material/Telegram';
import CryptoJS from 'crypto-js';

import { StyledDialogTitle } from '../StyledComponents';
import PasswordDialog from '../UserManager/PasswordDialog';
import userService from '../../services/userService';
import useErrorHandler from '../../services/error-handler';

const UserOffice = ({ open, onClose }) => {
    const { authStore } = useContext(AuthContext);

    const [passDialog, setPassDialog] = useState(null);
    const [telegram, setTelegram] = useState(false);
    const errorHandler = useErrorHandler();

    useEffect(() => {
        checkTg();
    }, [open]);

    const checkTg = async () => {
        if (!open) return;
        try {
            const user = authStore.user;
            const tg = await userService.checkTg(user.id);
            setTelegram(tg.data);
        } catch (e) {
            console.log('tg_bot_data >>>', e);
        }
    };

    const setPassword = async (password) => {
        try {
            const id = authStore.user.id;
            await userService.changePassword(id, password);
            setPassDialog(null);
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <StyledDialogTitle>Личный кабинет</StyledDialogTitle>
                <DialogContent>
                    <Typography>
                        Пользователь: {authStore.user.firstName} {authStore.user.lastName}
                    </Typography>

                    <Stack direction="row" alignItems="center">
                        <Typography>Сменить пароль:</Typography>
                        <Tooltip title="Сменить пароль">
                            <IconButton
                                color="error"
                                onClick={() => {
                                    const user = authStore.user;
                                    setPassDialog(user);
                                }}>
                                <LockResetIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                        <Typography>Подписка на телеграм:</Typography>
                        <Tooltip title={telegram ? 'Отписаться' : 'Подписаться'}>
                            <IconButton
                                color={telegram ? 'error' : 'primary'}
                                onClick={() => {
                                    const user = authStore.user;
                                    let command = telegram ? 'unregister_' : 'register_';
                                    command += user.id;
                                    command +=
                                        '_' +
                                        CryptoJS.MD5(
                                            user.login + user.firstName + user.lastName
                                        ).toString(CryptoJS.enc.Hex);
                                    window.open(
                                        'https://t.me/portal_kriolab_bot?start=' + command,
                                        '_blank'
                                    );
                                }}>
                                <TelegramIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
            <PasswordDialog
                user={passDialog}
                onCancel={() => setPassDialog(null)}
                onApply={setPassword}
            />
        </>
    );
};

export default UserOffice;
