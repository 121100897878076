import { makeAutoObservable } from 'mobx';

import OrderItemsService from '../services/orderItemsService';

export class OrderItemStore {
    loading = false;

    allItemsSize = 0; //количество записей в таблице
    pageSize = 10; //количество записей на одну страницу
    page = 0; //номер активной страницы
    items = []; //список записей
    filter = {}; //объект содержащий фильтры возможные поля: order_id, device_type, state_id, buyer_id
    sort = { column: 'create_time', order: 'DESC' }; // объект сортировки содержит поля column(item_id, order_id, device_type, description, quantity, create_time, comment), order(ASC, DESC)

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Загрузка количества записей в таблице
     */
    async loadSize() {
        this.loading = true;
        try {
            const response = await OrderItemsService.getSize(this.filter);
            this.allItemsSize = +response.data;
        } catch (e) {
            console.log(e);
            throw new Error('Ошибка получения размера данных с сервера');
        } finally {
            this.loading = false;
        }
    }

    /**
     * Загрузка списка записей
     */
    async loadItems() {
        this.loading = true;
        try {
            const start = this.page * this.pageSize;
            const response = await OrderItemsService.getItems(
                start,
                this.pageSize,
                this.filter,
                this.sort
            );
            this.items = response.data;
        } catch (e) {
            console.log(e);
            throw new Error('Ошибка получения записей с сервера');
        } finally {
            this.loading = false;
        }
    }

    /**
     * Обновление записи
     * @param orderItem обновляемая запись
     */
    async update(orderItem) {
        try {
            await OrderItemsService.update(orderItem);
            await this.loadItems();
        } catch (e) {
            console.log(e);
            throw new Error('Ошибка сохранения записи');
        }
    }

    /**
     * Устанавливает фильтр для экземпляра и загружает обновленные результаты.
     *
     * @param {Object} filter - Фильтр
     * @param {number} filter.order_id - ID заказа.
     * @param {number} filter.device_type - ID типа устройства.
     * @param {number} filter.state_id - ID состояния.
     * @param {number} filter.buyer_id - ID покупателя.
     */
    async setFilter(filter = {}) {
        this.filter = filter;
        this.page = 0;
        await this.loadSize();
        await this.loadItems();
    }

    /**
     * Устанавливает критерии сортировки для списка и перезагружает элементы.
     *
     * @param {Object} sort - Объект, содержащий критерии сортировки.
     * @param {string} sort.column - Название столбца для сортировки.
     * @param {string} sort.order - Направление сортировки. 'ASC' для сортировки по возрастанию; 'DESC' для сортировки по убыванию.
     */
    async setSort(sort = {}) {
        this.sort = sort;
        await this.loadItems();
    }

    /**
     * Устанавливает текущую страницу и загружает элементы.
     * @param {number} page - Номер страницы для установки.
     */
    async setPage(page) {
        this.page = page;
        await this.loadItems();
    }

    /**
     * Устанавливает размер страницы в заданное значение и загружает соответствующие элементы.
     * @param {number} size - Желаемый размер страницы.
     */
    async setPageSize(size) {
        this.pageSize = size;
        await this.loadItems();
    }

    /**
     * Асинхронно создает новый элемент заказа.
     * @param {string} order_id - Идентификатор заказа, к которому нужно добавить элемент.
     * @param {string} type_id - Идентификатор типа элемента заказа.
     * @param {string} description - Описание элемента заказа.
     * @returns {Promise} - Промис, который разрешается данными созданного элемента заказа.
     * @throws {Error} - Исключение, если создание элемента заказа не удалось.
     */
    async create(order_id, type_id, description = '') {
        const response = await OrderItemsService.add(order_id, type_id, description);
        if (response.data) {
            this.loadSize();
            this.loadItems();
            return response.data;
        } else {
            throw new Error('Ошибка создания пункта заказа');
        }
    }

    /**
     * Асинхронно удаляет указанный элемент, используя OrderItemsService.
     * Если удаление прошло успешно, метод загружает новый список элементов и их количество.
     * @param {Object} item - Элемент, который нужно удалить.
     * @throws {Error} Если удаление не удалось, метод выбрасывает исключение с сообщением об ошибке.
     */
    async delete(item) {
        const response = await OrderItemsService.delete(item);
        if (response.data) {
            this.loadSize();
            this.loadItems();
        } else {
            throw new Error('Ошибка удаления пункта заказа');
        }
    }
}
