import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Link,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';

import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import { OrderItemStore } from '../../store/orderItemStore';
import { StyledTableCell, StyledSortLabel } from '../StyledComponents';
import rangeToString from '../../services/range-string';

const AT_WORK = 2;
const KOSA = 0;
const ADC = 1;
const LOGGER = 16;
const CONTROLLER = 18;

const itemStore = new OrderItemStore();
const Production = () => {
    const { additionInformationStore } = useContext(AdditionInfoContext);

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const type = params['*'];

    useEffect(() => {
        let filter = { state_id: AT_WORK };
        //prettier-ignore
        switch (type) {
            case 'kosa'        : filter.device_type = KOSA;       break;
            case 'adc'         : filter.device_type = ADC;        break;
            case 'controllers' : filter.device_type = CONTROLLER; break;
            case 'loggers'     : filter.device_type = LOGGER;     break;
        }
        itemStore.setFilter(filter);
    }, [type]);

    const sortHandler = (sortColumnName) => {
        const isAsc = itemStore.sort.column === sortColumnName && itemStore.sort.order === 'asc';
        const sort = {
            column: sortColumnName,
            order: isAsc ? 'desc' : 'asc',
        };
        itemStore.setSort(sort);
    };
    const renderHeader = () => {
        //prettier-ignore
        const items = [
            { id: 'device_type',   label: 'Тип',          sort:true  },
            { id: 'description',   label: 'Название',     sort:true  },
            { id: 'quantity',      label: 'Количество',   sort:true  },
            { id: 'sns',           label: 'SN',           sort:false },
            { id: 'priority_id',   label: 'Приоритет',    sort:true  },
            { id: 'deadline_time', label: 'Срок',         sort:true  },
            { id: 'order_id',      label: '№ Заказа',     sort:true  },
            { id: 'create_time',   label: 'Время заказа', sort:true  },
            { id: 'comment',       label: 'Комментарий',  sort:true  },
        ];
        const out = [];
        items.forEach((item) => {
            if (item.sort) {
                out.push(
                    <StyledTableCell
                        key={item.id}
                        sortDirection={
                            itemStore.sort.column === item.id ? itemStore.sort.order : false
                        }>
                        <StyledSortLabel
                            active={itemStore.sort.column === item.id}
                            direction={itemStore.sort.order}
                            onClick={() => sortHandler(item.id)}>
                            {item.label}
                        </StyledSortLabel>
                    </StyledTableCell>
                );
            } else {
                out.push(
                    <StyledTableCell key={item.id}>
                        <StyledSortLabel disabled>{item.label}</StyledSortLabel>
                    </StyledTableCell>
                );
            }
        });
        return out;
    };
    const renderBody = () => {
        const printName = (item) => {
            return 'formula' in item ? (
                <Tooltip title="Показать подробности">
                    <Link
                        onClick={() =>
                            navigate(
                                `/formula/${item.device_type}/${item.order_id}/${item.item_id}`,
                                { state: { backgroundLocation: location } }
                            )
                        }
                        sx={{ cursor: 'pointer' }}>
                        {item.description}
                    </Link>
                </Tooltip>
            ) : (
                item.description
            );
        };
        const rowStyle = (item) => {
            //prettier-ignore
            switch (item.priority_id) {
                case 3  : return { backgroundColor: '#ff8073' };
                case 4  : return { backgroundColor: '#ff5240' };
                default : return null;
            }
        };
        const items = itemStore.items;
        return items.map((item) => (
            <TableRow key={item.item_id} hover sx={rowStyle(item)}>
                <TableCell>{additionInformationStore.deviceType(item.device_type)}</TableCell>
                <TableCell>{printName(item)}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{'sns' in item ? rangeToString(item.sns) : ''}</TableCell>
                <TableCell>{additionInformationStore.priorityStr(item.priority_id)}</TableCell>
                <TableCell>{DateTime.fromISO(item.deadline_time).toFormat('dd.MM.yyyy')}</TableCell>
                <TableCell>
                    <Tooltip title="Просмотреть заказ">
                        <Link href={`/orders/${item.order_id}`}>{item.order_number}</Link>
                    </Tooltip>
                </TableCell>
                <TableCell>{DateTime.fromISO(item.create_time).toFormat('dd.MM.yyyy')}</TableCell>
                <TableCell>{item.comment}</TableCell>
            </TableRow>
        ));
    };

    const renderSkeletonBody = () => {
        const str = () => {
            return new Array(8).fill(
                <TableCell>
                    <Skeleton />
                </TableCell>
            );
        };
        return new Array(itemStore.pageSize).fill(<TableRow>{str()}</TableRow>);
    };

    return (
        <>
            <Typography variant="h5" align="center" padding={2}>
                В производстве
            </Typography>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>{renderHeader()}</TableRow>
                    </TableHead>
                    <TableBody>{itemStore.loading ? renderSkeletonBody() : renderBody()}</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50]}
                                    component="div"
                                    count={itemStore.allItemsSize}
                                    rowsPerPage={itemStore.pageSize}
                                    page={itemStore.page}
                                    onPageChange={(_, p) => itemStore.setPage(p)}
                                    onRowsPerPageChange={(e) =>
                                        itemStore.setPageSize(e.target.value)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default observer(Production);
